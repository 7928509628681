@import url(https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@200&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ------------------------Theme colors ---------------------------- */
:root {
  --darkGreen: #203e1f;
  --midGreen: #46782f;
  --lightGreen: #69b23a;
  --darkBlue: #114657;
  --lightBlue: #1ba99b;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: aliceblue;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Image Formats */
.directoryPhoto {
  height: 600px;
  width: 75%;

}
.coverPhoto{
  /* height: 650px; */
  width: 100%;
  height: auto;
}
.aboutPhoto{
  height: 300px;
  width: 100%;
  border:3px dashed aliceblue;
  border-radius:12px;
}

/* elements */

body {
  font-family: "Open Sans", sans-serif;
  
}


h1 {
  font-family: "Lobster", sans-serif;
}

h2, h3 {
  font-family: "Libre Franklin", sans-serif;
}

/* classes */
.active {
  color: aliceblue;
  border-radius: 10%;
  background-color: #1ba99b;
  background-color: var(--lightBlue);
}

.babyCover {
  background-image: url(/static/media/cover6.95dc179e.png);
  width: 100%;
  height: 500px;
  background-position: center top;
  padding: 80px
}

.bg-img {
  background-image: url(/static/media/cover1.99f2201d.jpg);
  height: 650px;
  width: 100%;
}

.certificateCard {
  width: 40px;
  margin: 15px;
  color: red
}

.center {
  margin: 0px auto;
  width: 100%;
}
.headerContent{
  align-items: center;
  text-align: center;
  /* background-image: linear-gradient(to right, #57A0D3, #3046C5, #011B56); */
}
@media (max-width: 499.98px) {
  .headerContent{
    background-image: url(/static/media/cover4.e752b5c8.png);
    width: 100%;
    height: 650px;
    background-position: center;
  }
  .headerText {
    position: absolute;
    width: 100%;
    top: 340px;
    left: 50%;
    transform: translate(-50%, -50%);
    color: aliceblue;
    font-size: xx-large;
    font-family: "Lobster";
    background-color: rgba(0, 50, 40, 0.4);
  }
  .homeContent {
    height: 400px;
    padding: 50px;
    margin: 10px;
    border-radius: 30px;
  }
  

}
@media (min-width: 499.99px) and (max-width: 1599.98px) {
  .headerContent{
    background-image: url(/static/media/cover2.e8ea056b.jpg);
    width: 100%;
    height: 650px;
    background-position: center;
  }
  .headerText {
    position: absolute;
    top: 470px;
    left: 35%;
    /* transform: translate(-50%, -50%);
     */
    color: aliceblue;
    font-size: xx-large;
    font-family: "Lobster";
    background-color: rgba(9, 58, 46, 0.6);
    border-radius: 8%;
    padding: 10px;
  }
  .homeContent {
    height: 700px;
    padding: 50px;
  }
  .huskyCover {
    background-image: url(/static/media/cover5.eaa136de.png);
    width: 100%;
    height: 300px;
    background-position: center top;
    padding: 80px
  }
  
}
.homeText {
  background-color: rgba(32, 62, 31, 0.6);
  margin: 0 auto;
  padding: 20px;
  border-radius: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: aliceblue;
}


.headerText .borderText{
    font-size: 23px;
    text-shadow: -.5px .5px 0 white,
                  .5px .5px 0 white,
                 .5px -.5px 0 white,
                -.5px -.5px 0 white;
}




.row-content {
  padding: 50px 0;
  border-bottom: 1px ridge;
  min-height: 200px;
}

.row-end {
  border-bottom: 1px ridge;
}

.row-padding{
  padding: 50px 0;
}

.site-footer {
  /* background-image: linear-gradient(#114657, aliceblue); */
  background-color: #203e1f;
  background-color: var(--darkGreen);
  padding: 20px 0;
  color: aliceblue;
}
.site-footer a {
  color: aliceblue;
}

.jumbotron {
  color: black;
  
}

#headerJumbo {
  margin: 0;
  padding: 0;
  background-color: aliceblue;
}



.jumbotron h2 {
  color: #57A0D3;
}

.left-align{
  text-align: left;
}

.margin30 {
  margin: 30px;
  margin-top: 50px;
}
/* .navbar-dark {
  background-color: #57A0D3;
} */


.navbar{
  background-color: transparent;
}

.navbar.active{
  background-color: cornflowerblue;
}

.navbar.colorChange{
  background-color: #000;
}



Nav.Link .nav-link{
  color: white;
}



.portfolioImage {
  border-radius: 5%;
}

Button.portfolioButton {
  background-color: #114657;
  background-color: var(--darkBlue);
}

Button.portfolioButton:hover{
  background-color: #69b23a;
  background-color: var(--lightGreen);
  border-color: #69b23a;
  border-color: var(--lightGreen);
}

.sizeMedium {
  height:500px;
  width: 100%;
}
.sizeMedium img {
  width: 100%;
  border-radius: 1rem;
}
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*=============== blog_area css =============*/
.blog_area {
  background: #fafafa;
  padding: 120px 0px;
}

.bg_w {
  background: #fff;
}

.blog_post {
  cursor: pointer;
  transition: all 0.3s linear;
}

.blog_post .blog_img {
  position: relative;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
}

.blog_post .blog_img .post_date {
  position: absolute;
  width: 55px;
  height: 60px;
  font: 700 18px "Montserrat", sans-serif;
  color: #494949;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.95);
  text-align: center;
  top: 20px;
  right: 20px;
  line-height: 21px;
  display: flex;
  align-items: center;
  transition: all 0.4s linear;
}

.blog_post .post_content {
  padding: 25px;
  background: #fff;
  border: 1px solid #ebebeb;
  border-top: 0px;
}

.blog_post .post_content .blog-meta span {
  font: 500 15px/15px "Montserrat", sans-serif;
  color: #494949;
  display: inline-block;
  padding-right: 5px;
}

.blog_post .post_content .blog-meta span i {
  padding-right: 5px;
}

.blog_post .post_content .blog-meta span a {
  color: #494949;
}

.blog_post .post_content .blog-meta span + span {
  border-left: 1px solid #494949;
  padding-left: 12px;
}

.blog_post .post_content h2 {
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
  color: #494949;
  padding: 10px 0px 8px;
  transition: all 0.2s linear;
}
.blog_post .post_content h2:hover {
  color: #040c2c;
}
.blog_post .post_content .read_btn {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #797979;
  transition: color 0.3s linear;
}
.blog_post .post_content .read_btn i {
  vertical-align: middle;
  padding-left: 3px;
  transition: all 0.2s linear;
  display: inline-block;
}
.blog_post .post_content .read_btn:hover {
  color: #040c2c;
}
.blog_post .post_content .read_btn:hover i {
  transform: translateX(5px);
}
.blog_post:hover {
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.07);
}

.blog_post:hover .post_date {
  background: #040c2c;
  color: #fff;
}

.blog_img_img {
  height: 300px;
}

